import React from "react";
import {ReactComponent as LoadingImage} from "./../images/loadingIcon.svg";
export const LoadingAnimation = () => {
    return (
        <span className="relative flex h-8 w-8 rounded-full my-4 animate-bounce">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-info opacity-75"></span>
            <LoadingImage className="absolute inline-flex h-full w-full text-primary"/>
        </span>
    );
};
