import React from "react";
import {FaMoon, FaSun} from "react-icons/fa";
import Logo from "./../images/apple-touch-icon.png";

type NavBarProps = {
    theme: "light" | "dark",
    onThemeChanged: (theme: "light" | "dark") => void,
    lastRequest: null | Date
}

export class NavBar extends React.Component<NavBarProps> {
    render() {
        const {theme, lastRequest} = this.props;
        return (
            <div className="navbar bg-base-300 flex-col md:flex-row">
                <div className="md:navbar-start">
                    <button type="button"
                            className="btn btn-ghost normal-case text-xl flex gap-4 items-center mb-4 md:mb-0 h-fit">
                        <img src={Logo} alt="Logo" className="h-16 w-16 md:h-8 md:w-8"/>
                        <span className="hidden md:block">Server Dashboard - Battle of the Centerländ</span>
                    </button>
                </div>
                <div className="md:navbar-end gap-2">
                    <div>
                        Last Request: {lastRequest ? ` ${lastRequest.toLocaleTimeString()}` : null}
                    </div>
                    <button className="btn btn-ghost btn-circle" onClick={() => {
                        this.props.onThemeChanged(theme === "light" ? "dark" : "light");
                    }}>
                        <label className={`swap swap-rotate ${theme === 'dark' ? 'swap-active' : ''}`}>
                            <FaSun
                                className="h-5 w-5 swap-on"/>
                            <FaMoon
                                className="h-5 w-5 swap-off"/>
                        </label>
                    </button>
                </div>
            </div>
        );
    }
}

