import React, {Component} from "react";
import {IServerState} from "../types/IServerState";
import {IClients} from "../types/IClients";

type ServerStateProps = {
    serverState: IServerState,
    clients: IClients
};

export class ServerState extends Component<ServerStateProps> {
    render() {
        const {serverState, clients} = this.props;
        return (
            <div className="py-8 bg-base-200">
                <div className="container mx-auto">
                    <div className="flex flex-col gap-4 justify-center">
                        <div className="flex flex-col md:flex-row gap-4 px-4 md:px-0">
                            <div className="stats shadow">
                                <div className="stat">
                                    <div className="stat-title">Server Main Status</div>
                                    <div className="stat-value text-info">{serverState.mainState.string}</div>
                                </div>
                            </div>
                            <div className="stats shadow">
                                <div className="stat">
                                    <div className="stat-title">Server Ingame Status</div>
                                    <div className="stat-value text-info">{serverState.ingameState.string}</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 md:flex md:flex-row gap-4 px-4 md:px-0">
                            <div className="btn hover:btn-info gap-2">
                                Player
                                <div className="badge">{clients ? clients.players.length : 0}</div>
                            </div>
                            <div className="btn hover:btn-info gap-2">
                                AIs
                                <div className="badge">{clients ? clients.ais.length : 0}</div>
                            </div>
                            <div className="btn hover:btn-info gap-2 col-span-2">
                                Spectators
                                <div className="badge">{clients ? clients.spectators.length : 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
