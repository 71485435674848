import React, {Component} from "react";
import {LogEntry} from "../types/IMessageLog";
import {Message} from "../messages/MessageType";
import {GameState, PlayerState} from "../messages/GameState";
import {CardEvent} from "../messages/CardEvent";
import {ShotEvent} from "../messages/ShotEvent";

type StateViewProps = { log: LogEntry[], onLogRequest: () => void };

export class StateView extends Component<StateViewProps> {

    renderStates() {
        let lastGameState: GameState | null = null;
        const {log} = this.props;
        const stateHavingMessages = log.filter((item) => {
            const type = (JSON.parse(item.message) as Message).message;
            return (type === "GAME_STATE" || type === "RIVER_EVENT" || type === "SHOT_EVENT" || type === "CARD_EVENT") && item.from === "::Server::Broadcast::";
        });
        return stateHavingMessages.map((item, index) => {
            const type = (JSON.parse(item.message) as Message).message
            const h = this.switchStates(item, lastGameState);
            if (type === "GAME_STATE") {
                lastGameState = JSON.parse(item.message) as GameState;
            }
            return h;
        });
    }

    render() {
        return (
            <div className="w-full px-4 md:px-0 relative">
                <div className="flex flex-col gap-4">
                    <div className="card bg-base-200">
                        <div className="card-body">
                            <div>
                                <button type="button" className="btn btn-primary btn-sm"
                                        onClick={() => this.props.onLogRequest()}>Refresh
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 justify-center z-0">
                        {this.renderStates()}
                    </div>
                </div>
            </div>
        );
    }

    private switchStates(item: LogEntry, lastGameState: GameState | null) {
        const type = (JSON.parse(item.message) as Message).message
        if (type === "GAME_STATE") {
            const message = JSON.parse(item.message) as GameState;
            if (lastGameState) {
                const changes = this.generatePlayerStateChanges(message, lastGameState);
                return <>
                    <div className="flex justify-center">
                        <div className="card w-full bg-base-200 shadow-xl">
                            <div className="card-body">
                                <h2 className="card-title">
                                    Changes
                                </h2>
                                <div
                                    className="grid grid-cols-6 gap-1 w-full">
                                    {Array.from(changes).map(playerStateChange => this.playerStateChange(playerStateChange))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="card w-full bg-base-200 shadow-xl">
                            <div className="card-body">
                                <h2 className="card-title">
                                    {message.message}
                                </h2>
                                <div
                                    className="grid grid-cols-6 gap-1 w-full">
                                    {message.data.playerStates.sort((a, b) => a.turnOrder < b.turnOrder ? -1 : 1).map(playerState => this.playerState(playerState))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }


            return <div className="flex justify-center">
                <div className="card w-full bg-base-200 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title">
                            {message.message}
                        </h2>
                        <div
                            className="grid grid-cols-6 gap-1 w-full">
                            {message.data.playerStates.sort((a, b) => a.turnOrder < b.turnOrder ? -1 : 1).map(playerState => this.playerState(playerState))}
                        </div>
                    </div>
                </div>
            </div>
        }
        if (type === "CARD_EVENT") {
            const message = JSON.parse(item.message) as CardEvent;
            return <div className="flex justify-center">
                <div className="card w-full bg-base-200 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title items-baseline">
                            CARD: {message.data.card}
                            <p className="text-sm">from {message.data.playerName}</p>
                        </h2>
                        <div>
                            {message.data.playerStates.map((action, i) => (
                                <div className="flex flex-col gap-2">
                                    <h3 className="font-bold">Action: {i}</h3>
                                    <div className="grid grid-cols-6">
                                        {action.map((item, index) => {

                                            return <p key={index}>{this.playerState(item)}</p>
                                        })}
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>

        }
        if (type === "SHOT_EVENT") {
            const message = JSON.parse(item.message) as ShotEvent;
            return <div className="flex justify-center">
                <div className="card w-full bg-base-200 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title items-baseline">
                            SHOT: {message.data.shooterName}{" -> "}{message.data.targetName}
                        </h2>

                        <div>
                            {message.data.playerStates.map((action, i) => (
                                <div className="flex flex-col gap-2">
                                    <h3 className="font-bold">Action: {i}</h3>
                                    <div className="grid grid-cols-6">
                                        <p key={i}>{this.playerState(action)}</p>
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>

        }
    }

    private generatePlayerStateChanges(message: GameState, lastGameState: GameState) {
        const changes: Map<string, Array<[string, string, string]>> = new Map();
        message.data.playerStates.forEach((playerState, index) => {
            changes.set(playerState.playerName, []);
            if (playerState.turnOrder !== lastGameState.data.playerStates[index].turnOrder) {
                changes.get(playerState.playerName)?.push(["Turn Order", lastGameState.data.playerStates[index].turnOrder.toString(), playerState.turnOrder.toString()]);
            }
            // apply changes like this

            if (playerState.lives !== lastGameState.data.playerStates[index].lives) {
                changes.get(playerState.playerName)?.push(["Lives", lastGameState.data.playerStates[index].lives.toString(), playerState.lives.toString()]);
            }
            if (playerState.lembasCount !== lastGameState.data.playerStates[index].lembasCount) {
                changes.get(playerState.playerName)?.push(["Lembas", lastGameState.data.playerStates[index].lembasCount.toString(), playerState.lembasCount.toString()]);
            }
            if (playerState.suspended !== lastGameState.data.playerStates[index].suspended) {
                changes.get(playerState.playerName)?.push(["Suspended", lastGameState.data.playerStates[index].suspended.toString(), playerState.suspended.toString()]);
            }
            if (playerState.reachedCheckpoints !== lastGameState.data.playerStates[index].reachedCheckpoints) {
                changes.get(playerState.playerName)?.push(["Reached Checkpoints", lastGameState.data.playerStates[index].reachedCheckpoints.toString(), playerState.reachedCheckpoints.toString()]);
            }
            if (playerState.playedCards.length !== lastGameState.data.playerStates[index].playedCards.length) {
                changes.get(playerState.playerName)?.push(["Played Cards", "", playerState.playedCards.join(", ").replace(lastGameState.data.playerStates[index].playedCards.join(", "), "...")]);
            }
            if (playerState.currentPosition[0] !== lastGameState.data.playerStates[index].currentPosition[0] || playerState.currentPosition[1] !== lastGameState.data.playerStates[index].currentPosition[1]) {
                changes.get(playerState.playerName)?.push(["Position", lastGameState.data.playerStates[index].currentPosition.join(", "), playerState.currentPosition.join(", ")]);
            }
            if (playerState.direction !== lastGameState.data.playerStates[index].direction) {
                changes.get(playerState.playerName)?.push(["Direction", lastGameState.data.playerStates[index].direction, playerState.direction]);
            }

            if (playerState.spawnPosition[0] !== lastGameState.data.playerStates[index].spawnPosition[0] || playerState.spawnPosition[1] !== lastGameState.data.playerStates[index].spawnPosition[1]) {
                changes.get(playerState.playerName)?.push(["Spawn Position", lastGameState.data.playerStates[index].spawnPosition.join(", "), playerState.spawnPosition.join(", ")]);
            }
        });
        return changes;
    }

    private playerState(playerState: PlayerState) {
        return (
            <div className="grid grid-cols-2 gap-2 text-xs">
                <div className="font-bold">Name</div>
                <div>{playerState.playerName}</div>
                <div className="font-bold">Turn Order</div>
                <div>{playerState.turnOrder}</div>
                <div className="font-bold">Character</div>
                <div>{playerState.character}</div>
                <div className="font-bold">Position</div>
                <div>{playerState.currentPosition[0]}, {playerState.currentPosition[1]}</div>
                <div className="font-bold">Spawn Position</div>
                <div>{playerState.spawnPosition[0]}, {playerState.spawnPosition[1]}</div>
                <div className="font-bold">Direction</div>
                <div>{playerState.direction}</div>
                <div className="font-bold">Lives</div>
                <div>{playerState.lives}</div>
                <div className="font-bold">Lembas</div>
                <div>{playerState.lembasCount}</div>
                <div className="font-bold">Suspended</div>
                <div>{playerState.suspended}</div>
                <div className="font-bold">Reached Checkpoints</div>
                <div>{playerState.reachedCheckpoints}</div>
                <div className="font-bold">Played Cards</div>
                <div className="flex flex-col gap-1">{playerState.playedCards.map(card => (
                        <div className="btn btn-xs">{card}</div>
                    )
                )}</div>
            </div>
        );
    }

    private playerStateChange(playerStateChange: [string, Array<[string, string, string]>]) {
        return (
            <div className="grid grid-cols-2 gap-2 text-xs">
                <div className="font-bold">Name</div>
                <div>{playerStateChange[0]}</div>
                {playerStateChange[1].map(change => (
                    <>
                        <div className="font-bold">{change[0]}</div>
                        <div>{change[1]}{" -> "}{change[2]}</div>
                    </>
                ))}
            </div>
        );
    }
}
