import React, {Component} from "react";
import {LogEntry} from "../types/IMessageLog";
import {JsonViewer} from "@textea/json-viewer";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import {IClients} from "../types/IClients";
import {uniqueId} from "lodash";


type LogViewProps = { log: LogEntry[], clients: IClients | null };

export class LogView extends Component<LogViewProps, {
    showMessages: boolean,
    sortMessagesASC: boolean,

}> {

    private sessionNameCache: Map<string, string>;

    constructor(props: LogViewProps) {
        super(props);
        this.state = {
            showMessages: false,
            sortMessagesASC: false,

        }
        this.sessionNameCache = new Map<string, string>()
    }

    trycatch = (func: () => any, fail: any) => {
        try {
            return func()
        } catch (e) {
            return fail
        }
    }

    findPlayerName = (sessionId: string): string => {
        const {clients} = this.props;

        if (this.sessionNameCache.has(sessionId)) {
            return this.sessionNameCache.get(sessionId) || "";
        }
        if (clients) {
            const isAI = clients.ais.find(ai => ai.session === sessionId);
            if (isAI) {
                !this.sessionNameCache.has(sessionId) && this.sessionNameCache.set(sessionId, isAI.name);
                return isAI.name;
            } else {
                const isPlayer = clients.players.find(ai => ai.session === sessionId);
                if (isPlayer) {
                    !this.sessionNameCache.has(sessionId) && this.sessionNameCache.set(sessionId, isPlayer.name);
                    return isPlayer.name;
                } else {
                    const isSpectator = clients.spectators.find(ai => ai.session === sessionId);
                    if (isSpectator) {
                        !this.sessionNameCache.has(sessionId) && this.sessionNameCache.set(sessionId, isSpectator.name);
                        return isSpectator.name;
                    }
                }
            }

        }
        return "";
    }

    render() {
        const {log} = this.props;
        const {showMessages, sortMessagesASC} = this.state;
        return (
            <div className="container mx-auto px-4 md:px-0">
                <div>
                    <h2 className="text-xl font-bold mb-4 flex gap-4 items-center">Message Log</h2>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="form-control flex flex-row gap-4 items-center my-2">
                            <label className="label">
                                Show Full Message:
                            </label>
                            <input type="checkbox" className="toggle toggle-sm" checked={showMessages} onChange={() => {
                                this.setState({showMessages: !showMessages})
                            }}/>
                        </div>
                        <div className="divider divider-horizontal"></div>
                        <div className="form-control flex flex-row gap-4 items-center my-2">
                            <label className="label">
                                Order:
                            </label>
                            <button type="button" className="btn btn-sm btn-ghost btn-outline" onClick={() => {
                                this.setState({sortMessagesASC: !sortMessagesASC})
                            }}>{!sortMessagesASC ? <FaArrowUp/> : <FaArrowDown/>}</button>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="table table-compact w-full">
                            <thead>
                            <tr className="hidden md:table-row">
                                <th>Time</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Message</th>
                            </tr>
                            <tr className="table-row md:hidden">
                                <th></th>
                                <th>Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            {log.sort((a, b) => {
                                return sortMessagesASC ? a.timestamp - b.timestamp : b.timestamp - a.timestamp;
                            }).map(logEntry => (<tr key={uniqueId()}>
                                <th>
                                    <div className="flex flex-col gap-1">
                                        <div className="font-black text-lg md:text-sm">
                                            {new Date(logEntry.timestamp * 1000).toLocaleTimeString("de-DE", {timeZone: "Europe/London"})}
                                        </div>
                                        <div className="flex md:hidden flex-col gap-1">
                                            <div className="text-xs"
                                                 style={{color: "hsl(var(--bc) / 0.6)"}}>
                                                {this.findPlayerName(logEntry.from) === "" ?
                                                    <code>{logEntry.from}</code> : this.findPlayerName(logEntry.from)}
                                            </div>
                                            <div className="text-xs"
                                                 style={{color: "hsl(var(--bc) / 0.6)"}}>
                                                {this.findPlayerName(logEntry.to) === "" ?
                                                    <code>{logEntry.to}</code> : this.findPlayerName(logEntry.to)}
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <td className="hidden md:table-cell">
                                    {this.findPlayerName(logEntry.from) === "" ?
                                        <code>{logEntry.from}</code> : this.findPlayerName(logEntry.from)}
                                </td>
                                <td className="hidden md:table-cell">
                                    {this.findPlayerName(logEntry.to) === "" ?
                                        <code>{logEntry.to}</code> : this.findPlayerName(logEntry.to)}
                                </td>
                                <td className={showMessages ? 'bg-white' : ''}>
                                    {showMessages ?
                                        this.trycatch(() =>
                                                <JsonViewer value={JSON.parse(logEntry.message)}
                                                            defaultInspectDepth={1}/>,
                                            <p className="text-black">{logEntry.message}</p>)
                                        :
                                        this.trycatch(() => {
                                            return <div
                                                className="badge p-4 font-bold rounded-none">{(JSON.parse(logEntry.message) as {
                                                message: string
                                            }).message}</div>
                                        }, logEntry.message)
                                    }
                                </td>
                            </tr>))}
                            </tbody>
                            <tfoot>
                            <tr className="hidden md:table-row">
                                <th>Time</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Message</th>
                            </tr>
                            <tr className="table-row md:hidden">
                                <th></th>
                                <th>Message</th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
