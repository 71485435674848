import React, {Component} from "react";
import {IBoardConfiguration} from "../types/IConfigurations";
import {FaBreadSlice, FaCircle, FaEye, FaFlag, FaHome} from "react-icons/fa";
import {GiEagleHead} from "react-icons/gi";

enum FieldType {
    EMPTY, EYE, START, CHECKPOINT, LEMBAS, RIVER, HOLE, EAGLE
}

type BoardConfigurationViewProps = {
    configuration: IBoardConfiguration,
}


export class BoardConfigurationView extends Component<BoardConfigurationViewProps> {

    private wallMap = new Array<string>();

    constructor(props: BoardConfigurationViewProps) {
        super(props);
        this.wallMap = new Array<string>();
        console.log(props.configuration.walls);
    }

    buildBoard = (): Array<FieldType[]> => {
        const board = new Array<FieldType[]>();
        const {configuration} = this.props;
        for (let x = 0; x < configuration.width; x++) {
            const row = new Array<FieldType>();
            for (let x = 0; x < configuration.width; x++) {
                row.push(FieldType.EMPTY);
            }
            board.push(row);

        }

        configuration.checkPoints.forEach(checkPoint => {
            board[checkPoint[0]][checkPoint[1]] = FieldType.CHECKPOINT;
        });

        configuration.startFields.forEach(start => {
            board[start.position[0]][start.position[1]] = FieldType.START;
        });

        configuration.lembasFields.forEach(lembas => {
            board[lembas.position[0]][lembas.position[1]] = FieldType.LEMBAS;
        });

        configuration.riverFields.forEach(river => {
            board[river.position[0]][river.position[1]] = FieldType.RIVER;
        });

        configuration.holes.forEach(hole => {
            board[hole[0]][hole[1]] = FieldType.HOLE;
        });

        board[configuration.eye.position[0]][configuration.eye.position[1]] = FieldType.EYE;

        configuration.walls.forEach(wall => {
            this.wallMap.push(wall[0].join("") + "|" + wall[1].join(""));
        });

        configuration.eagleFields.forEach(eagle => {
            board[eagle[0]][eagle[1]] = FieldType.EAGLE;
        });

        return board;
    }

    hasWall = (x: number, y: number, position: "BOTTOM" | "RIGHT") => {
        const {configuration} = this.props;
        if ((x === configuration.width - 1 && position === "RIGHT") || (y === configuration.height - 1 && position === "BOTTOM")) {
            return false;
        }
        let nextField: [number, number] = [x + 1, y];
        if (position === "BOTTOM") {
            nextField = [x, y + 1];
        }
        return this.wallMap.includes(`${x}${y}|${nextField.join("")}`) || this.wallMap.includes(`${nextField.join("")}|${x}${y}`);
    }

    render() {
        const {configuration} = this.props;
        return (
            <div className="px-4 md:px-0">
                <div className="flex flex-col gap-4">
                    <div className="card bg-base-300 p-4">
                        <h3 className="text-lg font-bold mb-4 uppercase">{configuration.name}</h3>
                        <div className="flex gap-2">
                            <div className="btn btn-sm btn-primary gap-2">
                                Height
                                <div className="badge badge-primary">{configuration.height}</div>
                            </div>
                            <div className="btn btn-sm btn-primary gap-2">
                                Width
                                <div className="badge badge-primary">{configuration.width}</div>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="table table-compact table-zebra w-full">
                            <tbody>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">Checkpoints
                                </th>
                                <td>{configuration.checkPoints.length}</td>
                            </tr>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">Start Fields
                                </th>
                                <td>{configuration.startFields.length}</td>
                            </tr>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">Holes
                                </th>
                                <td>{configuration.holes.length}</td>
                            </tr>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">Lembas Fields
                                </th>
                                <td>{configuration.lembasFields.length}</td>
                            </tr>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">River Fields
                                </th>
                                <td>{configuration.riverFields.length}</td>
                            </tr>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">Eagle Fields
                                </th>
                                <td>{configuration.eagleFields.length}</td>
                            </tr>
                            <tr>
                                <th className="font-black flex flex-row gap-2 items-center">Walls
                                </th>
                                <td>{configuration.walls.length}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-center">
                        <div className="card bg-base-300 p-4 w-fit overflow-x-auto">
                            <div className="flex text-gray-100 gap-1">
                                {this.buildBoard().map((row, x) => {
                                    return (<div className="flex flex-col gap-1">
                                        {row.map((field, y) =>
                                            <div className="flex gap-1">
                                                <div className="flex flex-col gap-1">
                                                    <div
                                                        className={`w-4 h-4 md:w-6 md:h-6 flex items-center justify-center rounded ${field === FieldType.RIVER ? 'bg-blue-400' : 'bg-green-400/20'}`}>
                                                        {field === FieldType.EYE ?
                                                            <FaEye className="h-4 w-4"/> : ""}
                                                        {field === FieldType.LEMBAS ?
                                                            <FaBreadSlice className="h-4 w-4"/> : ""}
                                                        {field === FieldType.START ?
                                                            <FaHome className="h-4 w-4"/> : ""}
                                                        {field === FieldType.HOLE ?
                                                            <FaCircle className="h-4 w-4 text-slate-900"/> : ""}
                                                        {field === FieldType.CHECKPOINT ?
                                                            <FaFlag className="h-4 w-4"/> : ""}
                                                        {field === FieldType.EAGLE ?
                                                            <GiEagleHead className="h-4 w-4"/> : ""}
                                                    </div>
                                                    {this.hasWall(x, y, "BOTTOM") ?
                                                        <div
                                                            className="w-4 md:w-6 h-1 rounded bg-amber-600"></div> :
                                                        <div className="w-4 md:w-6 h-1"></div>}
                                                </div>
                                                {this.hasWall(x, y, "RIGHT") ?
                                                    <div className="w-1 h-4 md:h-6 rounded bg-amber-600"></div> :
                                                    <div className="w-1 h-4 md:h-66"></div>}
                                            </div>
                                        )}
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
