import React from "react";
import {ClientsTable} from "./ClientsTable";
import {IClients} from "../types/IClients";
import {FaEye, FaServer, FaUser} from "react-icons/fa";

export const ClientsView = (props: { clients: IClients | null }) => {
    return (
        <>
            <div className="container mx-auto px-4 md:px-0">
                <div className="grid grid-cols-1 gap-4">
                    <ClientsTable title="Player" clients={props.clients?.players} icon={<FaUser/>}/>
                    <div className="divider"></div>
                    <ClientsTable title="KIs" clients={props.clients?.ais} icon={<FaServer/>}/>
                    <div className="divider"></div>
                    <ClientsTable title="Spectators" clients={props.clients?.spectators} icon={<FaEye/>}/>
                </div>
            </div>
        </>
    );
};

