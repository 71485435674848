import React from 'react';
import './App.css';
import {NavBar} from "./components/NavBar";
import {IServerState} from "./types/IServerState";
import {LoadingAnimation} from "./components/LoadingAnimation";
import {ServerState} from "./components/ServerState";
import {Tab, TabsContainer} from "./components/TabsContainer";
import {ClientsView} from "./components/ClientsView";
import {IClients} from "./types/IClients";
import {ConfigurationsTab} from "./components/ConfigurationsTab";
import {IConfigurations} from "./types/IConfigurations";
import {IBlacklist} from "./types/IBlacklist";
import {BlacklistView} from "./components/BlacklistView";
import {IMessageLog, LogEntry} from "./types/IMessageLog";
import {LogView} from "./components/LogView";
import {StateView} from "./components/StateView";

type AppState = {
    error: string;
    errorDialog: boolean;
    tab: number,
    lastRequest: null | Date,
    host: string,
    port: number,
    logLoading: boolean,
    blacklistLoading: boolean,
    serverState: null | IServerState,
    initialDialog: boolean,
    connecting: boolean,
    connected: boolean,
    openTab: Tab,
    clients: null | IClients,
    theme: "light" | "dark",
    configurations: IConfigurations | null,
    blacklist: null | IBlacklist,
    log: null | LogEntry[],
};

class App extends React.Component<unknown, AppState> {

    private interval?: NodeJS.Timer;

    constructor(props: unknown) {
        super(props);

        this.state = {
            blacklistLoading: false,
            connecting: false,
            error: "",
            host: "localhost",
            port: 8080,
            initialDialog: true,
            logLoading: false,
            lastRequest: null,
            tab: 0,
            serverState: null,
            errorDialog: false,
            connected: false,
            openTab: "clients",
            clients: null,
            theme: 'dark',
            configurations: null,
            blacklist: null,
            log: null
        }
    }

    initializeConnection = () => {
        this.setState({connecting: true, initialDialog: false});
        this.requestOnline().then(online => {
            if (online) {
                this.interval = setInterval(() => {
                    this.requestServerState();
                    this.requestClients();
                    this.requestConfigurations();
                    this.requestBLacklist();
                    const {connecting} = this.state;
                    connecting && this.setState({connecting: false, connected: true});
                }, 2000);
            } else {
                this.setState({connecting: false, errorDialog: true, error: "Server is not online"});
            }
        })
    }

    requestOnline = (): Promise<boolean> => {
        const {host, port} = this.state;
        return fetch("http://" + host + ":" + port).then(() => {
            return true;
        }).catch(function () {
            return false;
        });
    }

    requestServerState = () => {
        const {host, port} = this.state;
        fetch("http://" + host + ":" + port + "/api/serverState").then(r => r.text().then(body => JSON.parse(body) as IServerState)).then((serverState) => {
            this.setState({serverState, lastRequest: new Date()});
        }).catch(reason => {
            this.setState({errorDialog: true, error: (reason as Error).message});
        });
    }

    requestClients = () => {
        const {host, port} = this.state;
        fetch("http://" + host + ":" + port + "/api/clients").then(r => r.text().then(body => JSON.parse(body) as IClients)).then(info => {
            this.setState({clients: info, lastRequest: new Date()});
        }).catch(reason => {
            clearInterval(this.interval);
            this.setState({errorDialog: true, error: (reason as Error).message});
        });
    }
    requestLog = () => {
        this.setState({logLoading: true});
        const {host, port} = this.state;
        fetch("http://" + host + ":" + port + "/api/log").then(r => r.text().then(body => JSON.parse(body) as IMessageLog)).then((log) => {
            this.setState({log: log.log, logLoading: false, lastRequest: new Date()});
        }).catch(reason => {
            clearInterval(this.interval);
            this.setState({errorDialog: true, error: (reason as Error).message});
        });
    }
    requestConfigurations = () => {
        const {host, port} = this.state;
        fetch("http://" + host + ":" + port + "/api/configurations").then(r => r.text().then(body => JSON.parse(body) as IConfigurations)).then((configurations) => {
            this.setState({configurations, lastRequest: new Date()});
        }).catch(reason => {
            this.setState({errorDialog: true, error: (reason as Error).message});
        });
    }
    requestBLacklist = () => {
        this.setState({blacklistLoading: true});
        const {host, port} = this.state;
        fetch("http://" + host + ":" + port + "/api/blacklist").then(r => r.text().then(body => JSON.parse(body) as IBlacklist)).then(blacklist => {
            this.setState({blacklist, blacklistLoading: false, lastRequest: new Date()});
        }).catch(reason => {
            clearInterval(this.interval);
            this.setState({errorDialog: true, error: (reason as Error).message});
        });
    }

    unBlock = (ip: string) => {
        const {host, port} = this.state;
        fetch("http://" + host + ":" + port + "/api/unblockIP", {
            method: 'POST',
            body: JSON.stringify({ip})
        })
            .then(r => r.text().then(body => JSON.parse(body) as IBlacklist)).then(blacklist => {
            this.setState({blacklist, blacklistLoading: false, lastRequest: new Date()});
        }).catch(reason => {
            clearInterval(this.interval);
            this.setState({errorDialog: true, error: (reason as Error).message});
        });
    }

    render() {
        const {
            initialDialog,
            serverState,
            clients,
            host,
            port,
            connecting,
            errorDialog,
            error,
            connected,
            openTab,
            theme,
            configurations,
            blacklist,
            log,
            lastRequest
        } = this.state;
        return (
            <>
                <NavBar
                    theme={theme}
                    onThemeChanged={(newTheme) => {
                        this.setState({theme: newTheme});
                        document.querySelector<HTMLElement>("html")?.setAttribute("data-theme", newTheme);
                    }}
                    lastRequest={lastRequest}/>
                {connected && serverState && clients && (<ServerState serverState={serverState} clients={clients}/>)}
                <div className="container mx-auto xl:grid xl:grid-cols-4 gap-8 py-4">
                    <div>
                        {connected && (
                            <TabsContainer playingClients={clients && clients.ais.length + clients.players.length}
                                           spectators={clients?.spectators.length} openTab={openTab}
                                           onTabChange={(tab) => {
                                               if (tab === 'messageLog' || tab === "stateView") this.requestLog();
                                               this.setState({openTab: tab})
                                           }}/>)}
                    </div>
                    <div className="xl:col-span-3">
                        {connected && openTab === 'clients' && (
                            <ClientsView clients={clients}/>
                        )}
                        {connected && openTab === 'configurations' && configurations && (
                            <ConfigurationsTab configurations={configurations}/>
                        )}
                        {connected && openTab === 'blacklist' && blacklist && (
                            <BlacklistView ips={blacklist.ips} onUnBlock={this.unBlock}/>
                        )}
                        {connected && openTab === 'messageLog' && log && (
                            <LogView log={log} clients={clients}/>
                        )}
                        {connected && openTab === 'stateView' && log && (
                            <StateView log={log} onLogRequest={this.requestLog}/>
                        )}
                    </div>
                </div>
                <div className={`modal ${initialDialog && 'modal-open'}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Server Connection</h3>
                        <div className="grid grid-cols-2 gap-4 pt-4">
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Server Host</span>
                                </label>
                                <input type="text" placeholder="Server Host" value={host}
                                       onChange={(event) => {
                                           this.setState({host: event.target.value})
                                       }}
                                       className="input input-bordered input-primary w-full max-w-xs"/>
                            </div>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Port</span>
                                </label>
                                <input type="number" placeholder="Port" value={port}
                                       onChange={(event) => {
                                           this.setState({port: Number.parseInt(event.target.value)})
                                       }}
                                       className="input input-bordered input-primary w-full max-w-xs"/>
                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                this.initializeConnection();
                            }}>Connect
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`modal ${connecting && 'modal-open'}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Connecting...</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4">
                            <code>http://{host}:{port}/</code>
                            <LoadingAnimation/>
                        </div>
                    </div>
                </div>
                <div className={`modal ${errorDialog && 'modal-open'}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Error</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4">
                            <p>{error}</p>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                this.setState({initialDialog: true, errorDialog: false});
                            }}>Retry
                            </button>
                        </div>
                    </div>

                </div>
            </>
        )
            ;
    }
}

export default App;
