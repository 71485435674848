import {Component} from "react";
import {IConfigurations} from "../types/IConfigurations";
import {GameConfigurationsTable} from "./GameConfigurationsTable";
import {BoardConfigurationView} from "./BoardConfigurationView";

type ConfigurationsTabProps = {
    configurations: IConfigurations
}

export class ConfigurationsTab extends Component<ConfigurationsTabProps, { tab: "game" | "board" }> {
    constructor(props: ConfigurationsTabProps) {
        super(props);
        this.state = {
            tab: "game"
        }

    }

    render() {
        const {configurations} = this.props;
        const {tab} = this.state;
        return (
            <div className="container mx-auto">
                <div className="mb-4">
                    <div className="tabs">
                        <button type="button"
                                onClick={() => {
                                    this.setState({tab: "game"})
                                }}
                                className={`w-1/2 tab tab-bordered tab-lg ${tab === "game" ? 'tab-active' : ''}`}>Game Configuration
                        </button>
                        <button type="button" onClick={() => {
                            this.setState({tab: "board"})
                        }}
                                className={`w-1/2 tab tab-bordered tab-lg ${tab === "board" ? 'tab-active' : ''}`}>Board Configuration
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    <div
                        className={"w-full transition overflow-hidden " + (tab === "game" ? 'opacity-100 translate-x-0 delay-200 h-auto' : 'opacity-0 translate-x-8 h-0')}>
                        <GameConfigurationsTable
                            configuration={configurations.gameConfiguration}
                        />
                    </div>
                    <div className={"w-full transition overflow-hidden " + (tab === "board" ? 'opacity-100 translate-x-0 delay-200 h-auto' : 'opacity-0 translate-x-8 h-0')}>
                        <BoardConfigurationView
                            configuration={configurations.boardConfiguration}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
