import React, {Component} from "react";
import {FaCog, FaEye, FaList, FaServer, FaStreetView, FaUser, FaUsers, FaUsersSlash} from "react-icons/fa";

export type Tab = "clients" | "configurations" | "blacklist" | "messageLog"|"stateView"
type TabsContainerProps = {
    openTab: Tab,
    onTabChange: (tab: Tab) => void,
    playingClients: null | number,
    spectators: undefined | number,
};

export class TabsContainer extends Component<TabsContainerProps> {
    changeTab = (tab: Tab) => {
        this.props.onTabChange(tab);
    }

    render() {
        const {openTab, playingClients, spectators} = this.props;
        return (
            <ul className="menu bg-base-200 p-4 gap-2 mb-4 xl:mb-0">
                <li>
                    <button type="button" className={`font-bold ${openTab === "clients" && 'active'}`}
                            onClick={() => {
                                this.changeTab("clients")
                            }}>
                        <FaUsers/>
                        <span>Clients</span>
                        <div className="hidden md:flex gap-4 ml-auto">
                            <div className="flex items-center gap-2">
                                <div className="flex items-center gap-1"><FaUser/>/<FaServer/></div>
                                {playingClients}
                            </div>
                            <div className="flex items-center gap-2">
                                <FaEye/>{spectators}
                            </div>
                        </div>
                    </button>
                </li>
                <li>
                    <button type="button" className={`font-bold ${openTab === "configurations" && 'active'}`}
                            onClick={() => {
                                this.changeTab("configurations")
                            }}><FaCog/>Configurations
                    </button>
                </li>
                <li>
                    <button type="button" className={`font-bold ${openTab === "blacklist" && 'active'}`}
                            onClick={() => {
                                this.changeTab("blacklist")
                            }}><FaUsersSlash/>Blacklist
                    </button>
                </li>
                <li>
                    <button type="button" className={`font-bold ${openTab === "messageLog" && 'active'}`}
                            onClick={() => {
                                this.changeTab("messageLog")
                            }}><FaList/>Message Log
                    </button>
                </li>
                <li><p className="divider"></p></li>
                <li>
                    <button type="button" className={`font-bold ${openTab === "stateView" && 'active'}`}
                            onClick={() => {
                                this.changeTab("stateView")
                            }}><FaStreetView/>State View
                    </button>
                </li>
            </ul>
        );
    }
}
