import React from "react";

export const BlacklistView = (props: { ips: string[], onUnBlock: (ip: string) => void }) => {
    const unBlock = (ip: string) => {
        props.onUnBlock(ip);
    }
    return (
        <div className="container mx-auto px-4 md:px-0">
            <div>
                <h2 className="text-xl font-bold mb-4 flex gap-4 items-center">Blacklist</h2>
                <div className="grid md:grid-cols-2">
                    <div className="overflow-x-auto">
                        <table className="table table-compact table-zebra w-full">
                            <thead>
                            <tr>
                                <th>IP</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.ips.map(client => (<tr>
                                <th className="w-full">{client}</th>
                                <td className="flex gap-4">
                                    <button type="button" className="btn btn-sm btn-warning" onClick={() => {
                                        unBlock(client)
                                    }}>Unblock
                                    </button>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
