import React, {Component} from "react";
import {IGameConfiguration} from "../types/IConfigurations";
import {FaBreadSlice, FaBullseye, FaClock, FaWater} from "react-icons/fa";
import {TbCards} from "react-icons/tb";

type GameConfigurationsTableProps = {
    configuration: IGameConfiguration,
}

export class GameConfigurationsTable extends Component<GameConfigurationsTableProps> {
    render() {
        const {configuration} = this.props;
        return (
            <div className="px-4 md:px-0">
                <div className="overflow-x-auto">
                    <table className="table table-zebra w-full">
                        <tbody>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center">Character Choice Timeout
                            </th>
                            <td>{configuration.characterChoiceTimeout}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center"><FaBreadSlice/> Start Lembas
                            </th>
                            <td>{configuration.startLembas}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center">
                                <FaBullseye/><FaBreadSlice/> Shot Lembas
                            </th>
                            <td>{configuration.shotLembas}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center"><TbCards/>Card Selection
                                Timeout
                            </th>
                            <td>{configuration.cardSelectionTimeout}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center"><FaWater/> River Move Count
                            </th>
                            <td>{configuration.riverMoveCount}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center"><FaClock/>Server Ingame
                                Delay
                            </th>
                            <td>{configuration.serverIngameDelay}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center">Revive Rounds</th>
                            <td>{configuration.reviveRounds}</td>
                        </tr>
                        <tr>
                            <th className="font-black flex flex-row gap-2 items-center">Maximum Rounds</th>
                            <td>{configuration.maxRounds}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
