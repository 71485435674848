import React from "react";
import {IClientsInfo} from "../types/IClients";
import {uniqueId} from "lodash";

export const ClientsTable = (props: {
    title: string,
    clients: IClientsInfo[] | undefined,
    className?: string,
    icon?: JSX.Element
}) => {
    return (
        <div className={props.className}>
            <h2 className="text-xl font-bold mb-4 flex gap-4 items-center">{props.icon}{props.title}</h2>
            <div className="overflow-x-auto">
                <table className="table table-compact table-zebra w-full">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Session ID</th>
                        <th>Reconnect Token</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.clients?.map(client => (<tr key={uniqueId()}>
                        <th>{client.name}</th>
                        <td><code>{client.session}</code></td>
                        <td><code>{client.id}</code></td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
